import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import { LuminaInputProps } from './Input';
import { ChangeEvent, KeyboardEvent, useEffect, useState, WheelEvent } from 'react';
import LuminaTypography from '../LuminaTypography';

export const shouldSame = (value: string, comparable: string, message: string): boolean | string =>
    value === comparable || message;

const TextInput = ({
    label,
    endAdornment,
    startAdornment,
    type,
    placeholder,
    margin,
    name,
    onChange,
    onBlur,
    onKeyDown,
    value,
    error,
    helper,
    disabled,
    multiline = false,
    preventMinusAndZeroPrefix,
    classes,
    sx,
    className
}: LuminaInputProps): JSX.Element => {
    const addCommas = (num: string): string =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const removeNonNumeric = (num: string): string => num?.toString().replace(/[^0-9]\\./g, '');
    const [thousandSeparatorValue, setThousandSeparatorValue] = useState(
        addCommas(removeNonNumeric((value as string) ?? ''))
    );

    const handleThousandSeparatorChange = (event?: ChangeEvent): void => {
        setThousandSeparatorValue(
            addCommas(
                removeNonNumeric((event?.target as HTMLTextAreaElement | HTMLInputElement).value)
            )
        );
    };

    const handlePreventMinusAndZeroPrefix = (event?: ChangeEvent): void => {
        if (onChange) {
            onChange({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target: {
                    value: Math.abs(
                        Number((event?.target as HTMLTextAreaElement | HTMLInputElement).value)
                    ).toString()
                }
            });
        }
    };

    const handleThousandSeparatorKeyboard = (event?: KeyboardEvent): void => {
        setThousandSeparatorValue(
            addCommas(
                removeNonNumeric((event?.target as HTMLTextAreaElement | HTMLInputElement).value)
            )
        );
    };

    /**
     * This useEffect needed to listen changes on thousandSeparatorValue if type is thousandSeparator.
     * because even we show user value like 1,000,000 but at the end we still want to give backend value
     * like 1000000 without doing any pre processing outside this form component to hide unknown unknown
     * and complexity.
     */
    useEffect(() => {
        if (onChange !== undefined && type === 'thousandSeparator') {
            onChange({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target: {
                    value: thousandSeparatorValue.replace(new RegExp(`\\.`, 'g'), '')
                }
            });
        }
    }, [thousandSeparatorValue]);

    useEffect(() => {
        // This useEffect needed to listen changes on value if type is thousandSeparator.
        // example of the case is, we force update the value with new value from parent component
        // and we want to show the value with thousand separator
        if (type == 'thousandSeparator') {
            setThousandSeparatorValue(addCommas(removeNonNumeric((value as string) ?? '')));
        }
    }, [value]);

    const computeError = (): string => {
        if (error?.type === 'required') {
            return error.message ?? 'Kolom ini wajib diisi';
        }

        if (error?.type === 'minValue') {
            return error.message ?? 'Kolom ini harus diisi dengan angka lebih besar';
        }

        if (error?.type === 'maxValue') {
            return error.message ?? 'Kolom ini harus diisi dengan angka lebih kecil';
        }

        if (error?.type === 'shouldSame') {
            return error.message ?? 'Kolom ini tidak sama dengan field sebelumnya';
        }

        if (error?.type === 'requiredSocialMedia') {
            return '';
        }

        if (error?.type === 'validUrl') {
            const protocolPattern = new RegExp('^(?:http)s?://');
            if (!protocolPattern.test(value as unknown as string))
                return 'Kolom harus diawali dengan http:// atau https://';
            else return 'Kolom ini tidak sesuai format, Contoh: https://www.lumina.mba';
        }

        return error?.message ?? 'Kolom ini tidak sesuai format';
    };

    return (
        <div className={`w-full ${margin ?? 'my-2'}`}>
            <InputLabel>
                <LuminaTypography
                    variant="14-regular"
                    className={disabled ? 'text-greyscale-200' : ''}>
                    {label}
                </LuminaTypography>
            </InputLabel>
            <InputBase
                multiline={multiline}
                onWheel={(e: WheelEvent<HTMLDivElement>) => (e.target as HTMLElement).blur()}
                endAdornment={<div style={{ paddingBottom: '2px' }}>{endAdornment}</div>}
                startAdornment={<div style={{ paddingBottom: '2px' }}>{startAdornment}</div>}
                type={type === 'thousandSeparator' ? 'text' : type}
                name={name}
                onChange={
                    type === 'thousandSeparator'
                        ? handleThousandSeparatorChange
                        : preventMinusAndZeroPrefix
                        ? handlePreventMinusAndZeroPrefix
                        : onChange
                }
                onBlur={type === 'thousandSeparator' ? handleThousandSeparatorChange : onBlur}
                onKeyDown={
                    type === 'thousandSeparator' ? handleThousandSeparatorKeyboard : onKeyDown
                }
                value={type === 'thousandSeparator' ? thousandSeparatorValue : value}
                disabled={disabled}
                placeholder={placeholder ?? 'placeholder'}
                classes={classes}
                sx={sx}
                className={`${className ?? 'bg-greyscale-50'} ${error && 'error'}`}
            />
            <LuminaTypography variant="12-regular" className="text-greyscale-500" component="p">
                {helper}
            </LuminaTypography>
            {error && (
                <LuminaTypography variant="12-regular" className="text-fire-400" component="p">
                    {computeError()}
                </LuminaTypography>
            )}
        </div>
    );
};

export default TextInput;
