import Button, { ButtonProps } from '@mui/material/Button';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import Link from 'next/link';
import { MouseEvent } from 'react';
export interface LuminaButtonTrackerProps {
    domain: TrackingDomain;
    eventName: string;
    props?: LuminaObject;
}

export type LuminaButtonProps = ButtonProps & {
    className?: string;
    href?: string;
    target?: string;
    tracker?: LuminaButtonTrackerProps;
    variant?: 'text' | 'outlined' | 'contained' | 'containedFire' | 'outlinedFire' | 'outlinedSky';
    /**
     * Since button design is getting more various and complex, we need to override the default MUI button className
     * This is used to override the default MUI button className since the className props is used for the outer div
     */
    innerClassName?: string;
};

const LuminaButton = ({
    className,
    href,
    target,
    children,
    onClick,
    tracker,
    innerClassName,
    ...props
}: LuminaButtonProps): JSX.Element => {
    const track = useLazyAuthTracking(tracker?.domain);

    const onButtonClicked = (event: MouseEvent<HTMLButtonElement>): void => {
        if (tracker) {
            track.event(tracker.eventName, tracker.props);
            track.moengageEvent(tracker.eventName);
        }

        if (onClick) {
            onClick(event);
        }
    };

    if (href && target) {
        return (
            <a href={href} target={target} className={className}>
                <Button {...props} className={innerClassName} fullWidth onClick={onButtonClicked}>
                    {children}
                </Button>
            </a>
        );
    }

    if (href) {
        return (
            <Link href={href}>
                <div className={className}>
                    <Button
                        {...props}
                        className={innerClassName}
                        fullWidth
                        onClick={onButtonClicked}>
                        {children}
                    </Button>
                </div>
            </Link>
        );
    }

    return (
        <div className={className}>
            <Button {...props} className={innerClassName} fullWidth onClick={onButtonClicked}>
                {children}
            </Button>
        </div>
    );
};

export default LuminaButton;
