import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import useWindowBreakpoints from 'core/hooks/useWindowBreakpoints';

type LuminaTypographyProps = TypographyProps & {
    desktopVariant?: TypographyProps['variant'];
    mobileVariant?: TypographyProps['variant'];
    variant?: TypographyProps['variant'];
    /**
     * Tooltip will be shown if showTooltip = True and children type is string
     */
    showTooltip?: boolean;
    tooltipContent?: string;
    component?: string;
};

const LuminaTypography = ({
    desktopVariant,
    mobileVariant,
    variant,
    children,
    showTooltip,
    tooltipContent,
    ...props
}: LuminaTypographyProps): JSX.Element => {
    const { isDesktopBreakpoints } = useWindowBreakpoints();

    const renderComponent = (): JSX.Element => {
        if (desktopVariant && mobileVariant) {
            return (
                <Typography
                    variant={isDesktopBreakpoints ? desktopVariant : mobileVariant}
                    {...props}>
                    {children}
                </Typography>
            );
        }

        if (desktopVariant) {
            return (
                <Typography variant={desktopVariant} {...props}>
                    {children}
                </Typography>
            );
        }

        if (mobileVariant) {
            return (
                <Typography variant={mobileVariant} {...props}>
                    {children}
                </Typography>
            );
        }

        return (
            <Typography variant={variant} {...props}>
                {children}
            </Typography>
        );
    };

    if (typeof children == 'string' && showTooltip) {
        return <Tooltip title={tooltipContent ?? children}>{renderComponent()}</Tooltip>;
    }

    return renderComponent();
};

export default LuminaTypography;
