import MuiModal from '@mui/material/Modal';
import useWindowBreakpoints from 'core/hooks/useWindowBreakpoints';
import { ReactElement } from 'react';

type ModalProps = {
    open: boolean;
    content: ReactElement;
    /**
     * If this props value is true, then the modal will be not able to closed if user click outside the area of the modal and need to
     * do action the we provided on the modal before user can close the modal
     */
    isPermanent?: boolean;
    /**
     * Prevent propagation when clicking outside of the modal
     * Related bug https://stackoverflow.com/questions/60436516/prevent-event-propagation-on-row-click-and-dialog-in-material-ui
     * another example is when we put the modal inside the <Link> or <a> tag, we need to prevent the propagation.
     * While this props set to true, it automatically set the dialog to be isPermanent as well
     */
    stopPropagation?: boolean;
} & ComponentWithSetOpenProps;

const Modal = ({
    open,
    setOpen,
    content,
    isPermanent,
    stopPropagation
}: ModalProps): JSX.Element => {
    const { isMobileBreakpoints, isTabletBreakpoints } = useWindowBreakpoints();
    //  need to separate stopPropagation props since it's make unintended permanent modal
    if (stopPropagation) {
        if (isTabletBreakpoints || isMobileBreakpoints) {
            return (
                <MuiModal
                    open={open}
                    onClose={isPermanent ? undefined : () => setOpen(false)}
                    onClick={(e) => e.stopPropagation()}
                    className="flex items-end justify-center">
                    <div className="w-full rounded-t-3xl overflow-hidden">{content}</div>
                </MuiModal>
            );
        }
    }

    if (isTabletBreakpoints || isMobileBreakpoints) {
        return (
            <MuiModal
                open={open}
                onClose={isPermanent ? undefined : () => setOpen(false)}
                className="flex items-end justify-center w-full">
                <div className="w-full rounded-t-3xl overflow-hidden">{content}</div>
            </MuiModal>
        );
    }

    return (
        <MuiModal
            open={open}
            onClose={isPermanent ? undefined : () => setOpen(false)}
            className="flex items-center justify-center">
            {content}
        </MuiModal>
    );
};

export default Modal;
