import { isNotNullAndNotUndefined } from 'core/utils';
import { OnboardingStagePrecedence } from './contexts/CompanyOnboardingContext';

export const getCurrentStagePrecedence = (stage?: CompanyOnboardingStage): number => {
    let currentStage = isNotNullAndNotUndefined(window.localStorage.getItem('onboarding_stages'))
        ? window.localStorage.getItem('onboarding_stages')
        : 'has_signed_up';

    if (stage) {
        currentStage = stage;
    }

    const currentStagePrecedence =
        OnboardingStagePrecedence[currentStage as CompanyOnboardingStage];

    return currentStagePrecedence;
};

export const isFinishCompanyOnboarding = (stage?: CompanyOnboardingStage): boolean => {
    if (isNotNullAndNotUndefined(stage)) {
        const currentStagePrecedence = getCurrentStagePrecedence(stage);

        return currentStagePrecedence > 4;
    }

    const currentStagePrecedence = getCurrentStagePrecedence();

    return currentStagePrecedence > 4;
};

export const isValidUrl = (urlString: string | null | undefined): boolean => {
    if (urlString === '' || !isNotNullAndNotUndefined(urlString)) {
        return true;
    }

    if (urlString) {
        const urlPattern = new RegExp(
            '^(?:http)s?://' + // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    return false;
};
