import TextInput from 'core/components/Input/TextInput';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Edit } from 'iconoir-react';
import { Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { isNotNullAndNotUndefined } from 'core/utils';

export const isPhoneNumber = (phoneNumber: string | null | undefined): boolean => {
    if (phoneNumber === '' || !isNotNullAndNotUndefined(phoneNumber)) {
        return true;
    }

    if (phoneNumber) {
        const phoneNumberPattern = new RegExp(
            '^(^\\+62|62|^08)?\\d{8,16}$' // +62 start in addornment
        );
        return !!phoneNumberPattern.test(phoneNumber);
    }

    return false;
};

type PhoneInputForm =
    | {
          control: Control<any, any>;
          errors: FieldErrors<any>;
          label?: string;
          placeholder?: string;
          name?: string;
          isEditable?: never;
          onEditPhoneNumber?: never;
          setOnEditPhoneNumber?: never;
      }
    | {
          control: Control<any, any>;
          errors: FieldErrors<any>;
          isEditable?: boolean;
          onEditPhoneNumber: boolean;
          setOnEditPhoneNumber: Dispatch<SetStateAction<boolean>>;
          label?: string;
          placeholder?: string;
          name?: string;
      };

const PhoneInput = ({
    control,
    errors,
    isEditable = false,
    onEditPhoneNumber,
    setOnEditPhoneNumber,
    label,
    name,
    placeholder
}: PhoneInputForm): JSX.Element => {
    return (
        <Controller
            name={name ?? 'phone_number'}
            control={control}
            rules={{
                required: 'No HP wajib diisi',
                validate: {
                    maxValue: (value: string) =>
                        value.length < 15
                            ? true
                            : 'Nomor handphone tidak boleh lebih dari 16 karakter',
                    minValue: (value: string) =>
                        value.length > 7
                            ? true
                            : 'Nomor handphone tidak boleh kurang dari 8 karakter',
                    validPhoneNumber: (value: string) => isPhoneNumber(value)
                }
            }}
            render={({ field }) =>
                !isEditable ? (
                    <TextInput
                        startAdornment={<p className="mr-1">+62</p>}
                        label={label ?? 'Nomor HP Anda'}
                        placeholder={field?.value?.substring(3) ?? placeholder}
                        defaultValue={field?.value}
                        {...field}
                        type="number"
                        error={errors.phone_number}
                    />
                ) : (
                    <TextInput
                        startAdornment={'+62'}
                        label={
                            <div className="flex justify-between">
                                <span className="text-black mb-1">
                                    {label ?? 'Nomor HP/Whatsapp (contact person)'}
                                </span>
                                <button
                                    type="button"
                                    onClick={() => {
                                        (setOnEditPhoneNumber as Dispatch<SetStateAction<boolean>>)(
                                            !onEditPhoneNumber
                                        );
                                    }}
                                    className="text-sky-600 flex cursor-pointer">
                                    <Edit />
                                    <Typography variant="14-medium">Ubah</Typography>
                                </button>
                            </div>
                        }
                        {...field}
                        type="number"
                        placeholder={field?.value?.substring(3)}
                        defaultValue={field?.value}
                        error={name ? errors[name] : errors.phone_number}
                        disabled={!onEditPhoneNumber}
                    />
                )
            }
        />
    );
};

export default PhoneInput;
